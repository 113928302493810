var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selectContainer"},[(_vm.label)?_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.label)+" ")]):_vm._e(),_c('model-list-select',_vm._b({class:[
      _vm.size === 'xl'
        ? 'base-select x-large'
        : _vm.size === 'large'
        ? 'base-select large'
        : _vm.size === 'medium'
        ? 'base-select medium'
        : _vm.size === 'xs'
        ? 'base-select xsmall'
        : _vm.size === 'auto'
        ? 'base-select auto'
        : 'base-select small',
      _vm.lock ? 'lock' : '' ],attrs:{"placeholder":_vm.placeholder,"list":_vm.list,"option-value":_vm.optionValue,"option-text":_vm.optionText,"custom-text":_vm.customText,"isDisabled":_vm.disabled},model:{value:(_vm.baseSelectValue),callback:function ($$v) {_vm.baseSelectValue=$$v},expression:"baseSelectValue"}},'model-list-select',_vm.$attrs,false)),(_vm.lock)?_c('IconLock',{staticClass:"icon lock lockselect"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }