var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"passwordContainer"},[(_vm.label)?_c('label',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('a',{staticClass:"password"},[((_vm.type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.updatedValue),expression:"updatedValue"}],class:[
        _vm.customSize === 'large' ? 'large' : '',
        _vm.customSize === 'medium' ? 'medium' : '',
        _vm.customSize === 'xlarge' ? 'xlarge' : '',
        _vm.customSize === 'small' ? 'small' : '',
        'base-password' ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.updatedValue)?_vm._i(_vm.updatedValue,null)>-1:(_vm.updatedValue)},on:{"change":function($event){var $$a=_vm.updatedValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.updatedValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.updatedValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.updatedValue=$$c}}}},'input',_vm.$attrs,false)):((_vm.type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.updatedValue),expression:"updatedValue"}],class:[
        _vm.customSize === 'large' ? 'large' : '',
        _vm.customSize === 'medium' ? 'medium' : '',
        _vm.customSize === 'xlarge' ? 'xlarge' : '',
        _vm.customSize === 'small' ? 'small' : '',
        'base-password' ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required,"type":"radio"},domProps:{"checked":_vm._q(_vm.updatedValue,null)},on:{"change":function($event){_vm.updatedValue=null}}},'input',_vm.$attrs,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.updatedValue),expression:"updatedValue"}],class:[
        _vm.customSize === 'large' ? 'large' : '',
        _vm.customSize === 'medium' ? 'medium' : '',
        _vm.customSize === 'xlarge' ? 'xlarge' : '',
        _vm.customSize === 'small' ? 'small' : '',
        'base-password' ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required,"type":_vm.type},domProps:{"value":(_vm.updatedValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.updatedValue=$event.target.value}}},'input',_vm.$attrs,false)),_c('a',{staticClass:"black",on:{"click":function($event){$event.preventDefault();return _vm.showAndHidePassword.apply(null, arguments)}}},[(_vm.showPassword)?_c('IconCloseEye'):_c('IconEye')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }