var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-field',[_c('button',_vm._g(_vm._b({class:[
      _vm.action === 'secondary'
        ? 'secondary'
        : _vm.action === 'tercery'
        ? 'tercery'
        : _vm.action === 'turquose'
        ? 'turquose'
        : _vm.action === 'green'
        ? 'green'
        : _vm.action === 'turquose-secondary'
        ? 'turquose-secondary'
        : _vm.action === 'tercery-secondary'
        ? 'tercery-secondary'
        : _vm.action === 'blue'
        ? 'blue'
        : _vm.action === 'blue-secondary'
        ? 'blue-secondary'
        : _vm.action === 'red'
        ? 'red'
        : _vm.action === 'red-secondary'
        ? 'red-secondary'
        : _vm.action === 'outline'
        ? 'outline'
        : _vm.action === 'outline-gray'
        ? 'outline-gray'
        : _vm.action === 'outline-secondary'
        ? 'outline-secondary'
        : 'principal',
      ,
      _vm.buttonClass ? _vm.buttonClass : '',
      'base-button',
      _vm.size === 'xlarge'
        ? 'xlarge'
        : _vm.size === 'large'
        ? 'large'
        : _vm.size === 'medium'
        ? 'medium'
        : 'small' ],attrs:{"disabled":_vm.loading || _vm.disabled}},'button',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"icon-button"},[_vm._t("icon")],2),_vm._t("text"),_c('clip-loader',{attrs:{"loading":_vm.loading,"color":_vm.action === 'secondary' || _vm.action === 'turquose-secondary'
          ? '#348abf'
          : 'white',"size":"20px"}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }