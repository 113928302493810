export default {
  SET_TAGS(state, data) {
    state.tags = data;
  },
  SET_SERVICES_TAGS(state, data) {
    state.servicesTags = data;
  },
  SET_ITINERARIES_TAGS(state, data) {
    state.itinerariesTags = data;
  },
};
