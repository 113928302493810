export default {
  list(state) {
    return state.tags;
  },
  getServicesTags(state) {
    return state.servicesTags;
  },
  getItinerariesTags(state) {
    return state.itinerariesTags;
  },
};
