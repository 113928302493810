<template>
  <i class="buttonLock">
    <IconLock class="icon is-small" />
  </i>
</template>

<script>
export default {
  name: "BaseButtonLock",
  inheritAttrs: false,
};
</script>

<style>
.buttonLock svg {
  height: 1.8em !important;
  width: 1.2em !important;
}
</style>
